<!--
 * @Author: your name
 * @Date: 2021-03-10 11:16:25
 * @LastEditTime: 2021-04-02 18:51:49
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\postfive\index.vue
-->
<template>
  <div class="theallmsg">
    <navigation :msg="msg"></navigation>
    <div class="mainclass">
      <div class="topmsgclass">
        <div class="gwclass">
          <div>{{ productmsg.set_meal_name }}<span></span></div>
        </div>
        <div class="jieclas">
          <div>
            {{ productmsg.fit_type }}
          </div>
        </div>
      </div>
      <!-- <div class="whyclass2">111111111111</div> -->
      <div class="paball">
        <div class="pab">
          <div class="whyclass">
            <div>为什么要使用萝卜猎手？</div>
            <div class="whymsg">
              <!-- <div></div> -->
              <div class="whymsg2">
                <!-- <div
                  @click="seesuoming(productmsg.buying_point)"
                  class="onemsgclass"
                >
                  {{ buying_point }}
                </div> -->
                <div id="div"></div>
              </div>
            </div>
          </div>
        </div>
        <img
          class="backimgclass"
          :src="backimg == '' ? noback : backimg"
          alt="back"
        />
      </div>

      <div
        class="projectclass"
        v-for="(item, index) in allproject"
        v-bind:key="index"
      >
        <div class="bxall">
          <div class="bxgang"></div>
          <div class="bx">{{ item.project }}</div>
        </div>

        <div
          v-for="(itemes, indexes) in JSON.parse(item.basics_field)"
          v-bind:key="indexes"
          class="taoclass"
        >
          <div class="naji">
            <div>{{ itemes.name }}</div>
            <div class="jiclassthe" @click="seesuoming(itemes.note)">
              查看说明
            </div>
          </div>
          <div>
            ¥
            <span class="starclass">{{
              (itemes.price + "").split(".")[0]
            }}</span
            >{{ "." + (itemes.price + "").split(".")[1] }}
          </div>
        </div>
      </div>
    </div>
    <kefu ref="kefuid"></kefu>
    <van-popup v-model="show"
      ><div class="shuoming">{{ showmsg }}</div></van-popup
    >
    <myalert @nometh="showalert = false" @okmeth="okmeth" :show="showalert">
      <div class="thealeclass">
        <img class="theimg" src="../../assets/tan.png" alt="" />
        <div class="themsgcc">
          <div>登录已过期，是否立即登录?</div>
        </div>
      </div>
    </myalert>
    <sharepage ref="sharitid"></sharepage>
    <share ref="shareid"></share>
    <img
      @click="previewImg(item)"
      class="imgdetailclass"
      v-for="(item, index) in imgdetail"
      v-bind:key="index"
      :src="item"
      alt=""
    />
    <div class="nomsgclass"></div>
    <bottomkefu
      @confirm="fqbd"
      :stylemsg="ishavemsg == true ? '' : 'background:#939393'"
      :projectmoney="productmsg.price"
      msg="发起背调"
    ></bottomkefu>
  </div>
</template>
<script>
import sharepage from "../../views/sharepage/index";
import share from "../../components/share/index";
import kefu from "../../components/kefu/index";
import E from "wangeditor";
import islogin from "../../../util/islogin";
import { ImagePreview } from "vant";
import bottomkefu from "../../components/bottomkefu";
export default {
  components: {
    kefu,
    sharepage,
    share,
    bottomkefu,
  },
  data() {
    return {
      show: false,
      msg: "",
      alltao: "",
      ishavemsg: false,
      imges: require("../../assets/postoneke.png"),
      productid: "", //产品 id
      productmsg: {
        price: "0.00",
      }, //套餐数据
      allproject: "", //所有项目
      backimg: "", //图片
      noback: require("../../assets/back.png"), //没有图片
      showmsg: "", //说明内容
      buying_point: "", //套餐买点
      editor: "", //富文本
      showalert: false,
      sharemsg: "",
      imgdetail: [],
    };
  },
  watch: {
    buying_point(oldmsg) {
      this.editor.txt.html(oldmsg); // 重新设置编辑器内容
    },
  },
  methods: {
    /**
     * 预览图片
     */
    previewImg(imgurl) {
      ImagePreview({
        images: [imgurl],
      });
    },

    /**
     * 获取商品详情（多张长图图片）
     */
    getMultiPictureDetails(stringmsg) {
      if (stringmsg) {
        return stringmsg.split(",");
      }
      return "";
    },

    okmeth() {
      if (this.sharemsg) {
        this.$router.push({
          path: "/login",
          query: {
            invitecode: this.sharemsg.share_phone,
          },
        });
      } else {
        this.$router.push("login");
      }
    },
    /**
     * 点击去邀请
     */
    clickmeth() {
      let obj = JSON.stringify({
        sharetitle: "背调套餐（最新）",
        sharedesc: "海量精选优质套餐，限时免费领取！",
        isshare: "1",
        sub_code: localStorage.getItem("phone"),
        id: this.$route.query.id,
        share_phone: localStorage.getItem("phone"),
      });
      localStorage.setItem("shareobj", obj);
    },

    clickmeth2() {
      let obj = JSON.stringify({
        sharetitle: this.productmsg.set_meal_name,
        sharedesc: this.productmsg.fit_type,
        isshare: "1",
        sub_code: localStorage.getItem("phone"),
        id: this.$route.query.id,
        share_phone: localStorage.getItem("phone"),
      });
      localStorage.setItem("shareobj", obj);
      this.$refs.sharitid.getmsg();
    },

    clickkefu() {
      this.$refs.kefuid.showmeth();
    },
    /**富文本 */
    fuwenben() {
      this.editor = new E(document.getElementById("div"));
      this.editor.create();
      this.editor.disable();
    },
    /**查看说明 */
    seesuoming(msg) {
      this.showmsg = msg;
      this.show = true;
    },
    fqbd() {
      if (!islogin()) {
        this.showalert = true;
        return;
      }
      if (!this.ishavemsg) return;
      let msgarray = []; //数据传输
      //获取所有项目
      for (let i = 0; i < this.allproject.length; i++) {
        msgarray = msgarray.concat(JSON.parse(this.allproject[i].basics_field));
      }

      let alltao = this.productmsg;
      alltao.set_id = msgarray;
      alltao.cname = this.productmsg.set_meal_name;
      this.$router.push({
        name: "tobacktwo",
        params: {
          taomsg: alltao,
          id: "1",
          isqrcode: this.$route.query.isqrcode,
        },
      });
    },
  },
  created() {
    this.clickmeth(); //分享功能
    this.productid = this.$route.query.id;
    let sharemm = JSON.parse(sessionStorage.getItem("sharemsg"));
    this.sharemsg = sharemm;

    if (sharemm != "" && sharemm != undefined && sharemm.urlsharees) {
      this.productid = sharemm.id;
    } else {
    }
    /**
     * 根据id获取产品信息
     */
    this.$http
      .post("/firm/v1/Product/setMealDetail", {
        reqType: "product",
        id: this.productid,
      })
      .then((res) => {
        this.ishavemsg = true;
        this.productmsg = JSON.parse(res.data).data;
        this.msg = this.productmsg.set_meal_name;
        this.allproject = JSON.parse(this.productmsg.project);
        this.backimg = this.productmsg.head_pic;

        //图片详情（多图）
        if (this.getMultiPictureDetails(this.productmsg.img_detail)) {
          this.imgdetail = this.getMultiPictureDetails(
            this.productmsg.img_detail
          );
        }

        /**买点 */
        this.buying_point = this.productmsg.buying_point;
        /**富文本 */
        this.fuwenben();

        this.clickmeth2();
      });
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
  },
};
</script>
<style scoped>
.starclass {
  font-size: 0.28rem;
}
.bxall {
  display: flex;
  align-items: center;
  padding-bottom: 0.1rem;
  border-bottom-color: #efefef;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 0.18rem;
}
.topmsgclass {
  width: 70%;
}
.imgdetailclass {
  width: 100%;
  display: block;
}
.themsgcc {
  font-size: 0.32rem;
  line-height: 0.48rem;
  color: #333333;
}
.thealeclass {
  text-align: center;
  margin: 0 0.25rem;
  margin-top: 0.62rem;
  margin-bottom: 0.8rem;
}
.theimg {
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.3rem;
}
.paball {
  position: relative;
}
.pab {
  border-radius: 0.2rem;
  overflow: hidden;
  margin-top: 0.55rem;
}
.backimgclass {
  height: 2rem;
  position: absolute;
  right: 0.3rem;
  top: -2rem;
  z-index: 1;
}
.onemsgclass {
  width: 3.7rem;
}
.theallmsg >>> .van-popup--center {
  border-radius: 0.2rem;
  width: 70%;
}
.shuoming {
  padding: 0.3rem;
  line-height: 0.4rem;
  font-size: 0.26rem;
}
.thetip {
  background-color: rgb(74, 74, 74);
  color: #ffffff;
  padding: 0.3rem;
  position: absolute;
  margin-left: 1.8rem;
}
.thetip::after {
  content: "\00a0";
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 10px;
  border-color: transparent #f3961c transparent transparent;
}
.tipclass {
  display: flex;
  align-items: center;
  position: relative;
}
.projectclass {
  margin: 0 0.12rem;
  margin-top: 0.3rem;
}

.jiclassthe {
  font-size: 0.24rem;
  font-weight: 400;
  color: #4571d0;
  line-height: 0.28rem;
  margin-left: 0.2rem;
}
.naji {
  display: flex;
  align-items: center;
}
.mcl1 > div:nth-child(2) {
  font-size: 0.24rem;
  line-height: 0.33rem;
}
.mcl1 > div {
  text-align: center;
}
.mcl1 {
  font-size: 0.38rem;
  line-height: 0.53rem;
  color: #f55814;
  font-weight: bold;
}
.kecl > div:first-child {
  text-align: center;
}
.kecl > div:nth-child(2) {
  text-align: center;
  color: #003aff;
  margin-top: 0.03rem;
}
.kecl img {
  width: 0.49rem;
  height: 0.48rem;
}
.btp {
  background: linear-gradient(to right, #f55613, #fc9e46);
  border: none;
  color: #ffffff;
  padding: 0.24rem 1.43rem;
  border-radius: 0.2rem;
  font-size: 0.3rem;
  line-height: 0.42rem;
}
.paymoneyclass {
  position: fixed;
  padding: 2% 3%;
  width: 94%;
  background-color: #ffffff;
  bottom: 0;
  /* box-shadow: 0 -0.3rem 0.3rem #f5f7fa; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.taoclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.28rem;
}
.taoclass > :first-child {
  font-size: 0.28rem;
  font-weight: 500;
  color: #606266;
  line-height: 0.62rem;
}
.taoclass > :nth-child(2) {
  font-size: 0.24rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.62rem;
}
.bx {
  display: inline-block;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #303133;
  line-height: 0.62rem;
}
.bxgang {
  display: block;
  width: 0.1rem;
  height: 0.44rem;
  border-radius: 0.05rem;
  background: #ff6d20;
  margin-right: 0.2rem;
}
/* .whymsg > div:first-child {
  width: 0.13rem;
  height: 0.13rem;
  border-radius: 50%;
  border: 0.1rem solid #ff6a29;
  margin-right: 0.18rem;
} */
.whymsg {
  display: flex;
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #6f6f6f;
  margin-top: 0.14rem;
  margin-bottom: 0.08rem;
}
.whymsg2 {
  z-index: 1;
}
.whyclass > div:first-child {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #222222;
  font-weight: bold;
}
#div >>> .w-e-toolbar {
  display: none;
}
#div >>> .w-e-text {
  padding: 0;
  width: 100%;
}
#div >>> .w-e-text-container {
  height: auto !important;
  border: none !important;
  z-index: 999 !important;
}
#div >>> .w-e-content-mantle {
  z-index: 999 !important;
}
#div >>> b {
  font-weight: bold !important;
}
#div >>> i {
  font-style: italic;
}
#div >>> u {
  text-decoration: underline;
}
.theallmsg >>> .w-e-content-preview p {
  margin: 0;
}
.whyclass {
  background-size: 100% 100%;
  /* height: 2.07rem; */
  position: relative;
  padding: 0.08rem 0.3rem;
  border: 0.17rem solid;
  border-image: linear-gradient(#ffc05d, #ff6600) 10;
  background-image: url("../../assets/backimg3.png");
  background-size: 100% 100%;
}
.mainclass {
  margin: 0.4rem 0.2rem;
  margin-bottom: 0.3rem;
}
.jieclas {
  font-size: 0.24rem;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 0.34rem;
}
.theallmsg {
  background-color: #ffffff;
  min-height: 100%;
}
.gwclass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.06rem;
}
.gwclass > div:nth-child(2) {
  background-color: #f55814;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 1rem;
  overflow: hidden;
  padding: 0.06rem 0.19rem;
  color: #ffffff;
}
.gwclass > div:first-child {
  font-size: 0.46rem;
  font-weight: 500;
  color: #222222;
  line-height: 0.64rem;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.gwclass span {
  color: #f55814;
}
.nomsgclass {
  height: 1.8rem;
}
</style>